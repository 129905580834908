import React, { useContext, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { UsuarioContext } from "./common/context/UsuarioContext";


const Login = (props/*{linguagem, logado, loginToParent}*/) => { 
    const navigate = useNavigate();
    const {logado, setLogado} = useContext(UsuarioContext);

    useEffect(() => {
        if(logado){
          localStorage.setItem("logado",true); 
          navigate("/principal");
        }
    }, [logado]);

    useEffect(() => {
      //Refresh cache - 2 dias
      let dataAtual = new Date();
      if(localStorage.getItem('dataCache')){
          let dataCache = new Date (localStorage.getItem('dataCache'));
          let dataComparacao  = new Date(localStorage.getItem('dataCache'));
          if(dataCache)
              dataComparacao.setDate(dataCache.getDate() + 2);

          if(dataCache && dataComparacao < dataAtual){
              recarregaCache();
              dataCache = dataAtual;
              localStorage.setItem('dataCache', dataCache.toString());
          }
      } else {
          localStorage.setItem('dataCache', dataAtual.toString());
      }
    }, []);

    const recarregaCache = () => {
      window.location.reload(true);
    };

    function handleSubmit(e) {
        e.preventDefault();
    
        var login = "login@charifabebe.com.br";
        var senha = "123456";

        if (!e.target.email.value) {
          alert("Preencha o Email");
        } else if (!e.target.email.value) {
          alert("Email inválido");
        } else if (!e.target.password.value) {
          alert("Preencha a senha");
        } else if (
          e.target.email.value === login/*"login@aesop.com"*/ &&
          e.target.password.value === senha/*"123456"*/
        ) {
          e.target.email.value = "";
          e.target.password.value = "";
          setLogado(true);
          //props.loginToParent("true", "/mapa");
        } else {
          alert("Email ou Senha inválido");
        }
      };
      

    function exibePagina() {
        return (
            <center>
            <div className="Login">
            <h3>Login</h3>
            <form className="form" onSubmit={handleSubmit}>
                <div className="input-group">
                <label htmlFor="email">Email</label>
                <input type="email" name="email" placeholder="nome@email.com.br" />
                </div>
                <div className="input-group">
                <label htmlFor="password">Senha</label>
                <input type="password" name="password" />
                </div>
                <button className="primary">ENTRAR</button>
            </form>
            </div>
            </center>
        );
    }

  
    return (exibePagina());
  
}

export default Login;
