import './App.css';
import {React,  useState } from 'react';
import {
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import { UsuarioContext } from './common/context/UsuarioContext';
import Principal from './Principal';

function App() {
  const [logado, setLogado] = useState(localStorage.hasOwnProperty("logado") ? false : localStorage.getItem("logado"));

  return (
      <div>      
        <UsuarioContext.Provider value={{logado, setLogado}}>
          <Routes>
              <Route element = { <Home/> }  path="/" />
              <Route element = { <Login/> }  path="/login" />
              <Route element = { <Principal/> }  path="/principal" />
          </Routes>
        </UsuarioContext.Provider>
      </div>

    );
  
}

export default App;