import { useContext, useEffect, useState } from "react";
import { UsuarioContext } from "./common/context/UsuarioContext";
import { useNavigate } from "react-router-dom";
import './Principal.css';
import { format, subHours } from 'date-fns';

const Principal = () => {
    const {logado} = useContext(UsuarioContext);
    const navigate = useNavigate();
    const url = 'https://programandodozero.com.br/cha_rifa/api.php';
    const [products, setProducts] = useState(null);
    const [selectedEntregouProduct, setSelectedEntregouProduct] = useState(null);
    var isEvenRow = false;

    const formatarDataHora = (dataHora) => {
        if (dataHora === null || dataHora === "") {
          return '';
        }
        // Converte a string de data e hora para um objeto Date
        const dataHoraObj = new Date(dataHora);
    
        // Subtrai 3 horas
        const dataHoraSubtraida = subHours(dataHoraObj, 3);
    
        // Formata a data e hora no padrão brasileiro
        return format(dataHoraSubtraida, "dd/MM/yyyy HH:mm:ss");
      };

    const fetchDataPeriodically = async () => {
        try {
          // Substitua a URL com a sua endpoint ou URL desejada
          const response = await fetch(url);
          const data = await response.json();
          setProducts(data);
          //console.log('Dados atualizados:', data);
          // Adicione a lógica para lidar com os dados recebidos, se necessário
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        }
    };

    useEffect(() => {
        if (!logado) {
            navigate("/");
            return;
        }

        document.title = "Painel - Chá Rifa do Henry";
        // Simulando uma requisição à API (substitua pelo seu endpoint real)
        fetch(url)
            .then((response) => response.json())
            .then((data) => setProducts(data))
            .catch((error) => console.error('Erro ao obter dados da API:', error));
    
            // Define o intervalo para chamar fetchDataPeriodically a cada 5 segundos (5000 milissegundos)
        setInterval(fetchDataPeriodically, 10000);
    
        // Limpa o intervalo quando o componente
    }, []);

    const handleIDClick = (product) => {
        const resultado = products.find(elemento => elemento.id === product.id);
    
        if (resultado !== undefined) {
            setSelectedEntregouProduct(product); 
          //console.log(`Elemento encontrado: ${resultado, product}`);
        } else {
          console.log('Elemento não encontrado');
        }
        
      };

      const handleFormSubmit = (e) => {
        e.preventDefault();
        
        // Aqui você pode fazer a requisição POST com os dados preenchidos
        const postData = {
          id: selectedEntregouProduct.id,
        };
    
        // Simulando uma requisição POST (substitua pelo seu endpoint real)
        fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log('PUT bem-sucedido:');
            setSelectedEntregouProduct(null); // Limpa o produto selecionado após o POST
            fetch(url)
            .then((response) => response.json())
            .then((data) => setProducts(data))
            .catch((error) => console.error('Erro ao obter dados da API:', error));
          })
          .catch((error) => console.error('Erro no PUT:', error));
      };
//crie um array que percorra products e crie uma tabela listando todos os produtos que estão com nome preenchido, listando id, nome, telefone e preco
    return (
        <div>
            <h2>Números Marcados:</h2>
            <table style={{width: '100%'}}  className="table-consulta">
                <thead>
                    <tr>   
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Telefone</th>
                        <th>Email</th>
                        <th>Preço</th>
                        <th>Data Marcação</th>
                        <th>IP</th>
                        <th>Entregou</th>
                        <th>Data Entrega</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {products && products.map((product, index) => {
                        if(product.nome !== null && product.nome !== ""){
                            isEvenRow  = !isEvenRow;

                            return (
                                <tr key={index} style={{ background: isEvenRow ? '#c7c5c5' : '#f0f0f0'}} >
                                    <td>{product.id}</td>
                                    <td>{product.nome}</td>
                                    <td>{product.telefone}</td>
                                    <td>{product.email}</td>
                                    <td>{product.preco}</td>
                                    <td>{formatarDataHora(product.data_marcacao)}</td>
                                    <td>{product.ip}</td>
                                    <td>{product.entregou === "S" ? "Sim" :  <a href='#' onClick={() => handleIDClick(product)}>{"Marcar"}</a>}</td>
                                    <td>{formatarDataHora(product.data_entrega)}</td>
                                </tr>
                            );
                        }
                    })}
                </tbody>
            </table>
            <br/>
            <center>
                Total de Números Marcados: {products && products.filter((product) => product.nome !== null && product.nome !== "").length}
            </center>
            <br/>

            {selectedEntregouProduct && (
            <div className="popup-overlay">
              <div className="popup-form">
                <span className="close-btn" onClick={() => setSelectedEntregouProduct(null)}>X</span>
                <form onSubmit={handleFormSubmit}>
                  <h2>Você Irá Confirmar a Entrega do Presente de {selectedEntregouProduct.id + " - " + selectedEntregouProduct.nome}</h2>
                  <center>
                    <h2>{selectedEntregouProduct.preco}</h2>
                    <button className='button-salvar' type="submit">Confirmar</button>
                    <button className='button-sair' onClick={() => {setSelectedEntregouProduct(null);}}>Sair</button>
                  </center>
                </form>
              </div>
            </div>
          )}
        </div>

        
    );
    
};

export default Principal;