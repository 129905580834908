import React, { useEffect, useState } from 'react';
import './Home.css';

function Home() {
    const url = 'https://programandodozero.com.br/cha_rifa/api.php';
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [escolheu, setEscolheu] = useState(null);
    
    const [telefoneInvalido, setTelefoneInvalido] = useState(false);
    
    const [ipAddress, setIpAddress] = useState(''); 
    
    const [nomeInvalido, setNomeInvalido] = useState(null);
  
    const fetchDataPeriodically = async () => {
      try {
        // Substitua a URL com a sua endpoint ou URL desejada
        const response = await fetch(url);
        const data = await response.json();
        setProducts(data);
        //console.log('Dados atualizados:', data);
        // Adicione a lógica para lidar com os dados recebidos, se necessário
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };
  
    useEffect(() => {
      document.title = "Chá Rifa do Henry";
      // Simulando uma requisição à API (substitua pelo seu endpoint real)
      fetch(url)
        .then((response) => response.json())
        .then((data) => setProducts(data))
        .catch((error) => console.error('Erro ao obter dados da API:', error));

      const fetchIp = async () => { 
        try { 
          const response = await fetch('https://api.ipify.org?format=json'); const data = await response.json(); setIpAddress(data.ip); 
        } catch (error) { 
          console.error(error); } 
        }; 
      
      fetchIp();
  
        // Define o intervalo para chamar fetchDataPeriodically a cada 5 segundos (5000 milissegundos)
      const intervalId = setInterval(fetchDataPeriodically, 10000);
  
      // Limpa o intervalo quando o componente é desmontado
      return () => clearInterval(intervalId);
    }, []);
  
    const handleIDClick = (product) => {
      const resultado = products.find(elemento => elemento.id === product.id);
  
      if (resultado !== undefined) {
        if(resultado.nome === null || resultado.nome === "")
          setSelectedProduct(product); 
        //console.log(`Elemento encontrado: ${resultado}`);
      } else {
        console.log('Elemento não encontrado');
      }
      
    };
  
    const handleFormSubmit = (e) => {
      e.preventDefault();
      
      // Aqui você pode fazer a requisição POST com os dados preenchidos
      const postData = {
        id: selectedProduct.id,
        novoNome: e.target.elements.novoNome.value,
        novoTelefone: e.target.elements.novoTelefone.value,
        novoEmail: e.target.elements.novoEmail.value,
        novoIP: ipAddress,
      };
  
      if(postData.novoTelefone.length < 8) {
        setTelefoneInvalido(true);
        return;
      } else {
        setTelefoneInvalido(false);
      }
  
      if(postData.novoNome.length < 2 || /^\d+$/.test(postData.novoNome)) {
        setNomeInvalido(true);
        return;
      } else {
        setNomeInvalido(false);
      }
  
      // Simulando uma requisição POST (substitua pelo seu endpoint real)
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('POST bem-sucedido:');
          setEscolheu(selectedProduct);
          setSelectedProduct(null); // Limpa o produto selecionado após o POST
          fetch(url)
          .then((response) => response.json())
          .then((data) => setProducts(data))
          .catch((error) => console.error('Erro ao obter dados da API:', error));
        })
        .catch((error) => console.error('Erro no POST:', error));
    };
  
    return (
      <div className='container'>
        <div className='bg'></div>
        <center>
          <h1>Chá Rifa do Henry</h1>
        </center>
        <center>
        <h4>Para escolher o seu número, clique sobre ele.</h4>
        </center>
        <table className="table-rifa">
          <thead>
          </thead>
          <tbody>
            {products.reduce((rows, product, index) => {
                if (index % 10 === 0) {
                  rows.push([]);
                }
                rows[rows.length - 1].push(product);
                return rows;
              }, []).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((product) => (
                    <td key={product.id}>
                      <a className={product.nome !== null && product.nome !== "" ? "disabled" : ""} href='#' onClick={() => handleIDClick(product)}>{product.id}</a>
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
  
        {/*selectedProduct && (
          <form onSubmit={handleFormSubmit}>
            <h2>Formulário para {selectedProduct.id}</h2>
            <label>
              Nome:
              <input type="text" name="novoNome" required />
            </label>
            <br />
            <label>
              Telefone:
              <input type="text" name="novoTelefone" required />
            </label>
            <br />
            <label>
              Email:
              <input type="text" name="novoEmail" required />
            </label>
            <br />
            <button type="submit">Concluir</button>
          </form>
        )*/}
  
      {selectedProduct && (
        <div className="popup-overlay">
          <div className="popup-form">
            <span className="close-btn" onClick={() => setSelectedProduct(null)}>X</span>
            <form onSubmit={handleFormSubmit}>
              <h2>Você Selecionou o Número {selectedProduct.id}</h2>
              <h5>Para participar você deve finalizar, preenchendo os seus dados.</h5>
              <label>
                Nome:
                <input type="text" name="novoNome" required />
              </label>
              {nomeInvalido && (
                <p style={{ color: 'red', fontSize:10  }}>O nome deve ter pelo menos 3 caracteres.</p>
              )}
              <br />
              <label>
                Telefone:
                <input type="number" name="novoTelefone" required />
              </label>
              {telefoneInvalido && (
                <p style={{ color: 'red', fontSize:10 }}>O telefone deve ter pelo menos 8 caracteres.</p>
              )}
              <br />
              <label>
                Email:
                <input type="email" name="novoEmail" />
              </label>
              <br />
              <center>
                <button className='button-salvar' type="submit">Salvar</button>
                <button className='button-sair' onClick={() => {setSelectedProduct(null); setNomeInvalido(false); setTelefoneInvalido(false)}}>Sair</button>
              </center>
            </form>
          </div>
        </div>
      )}
  
      {escolheu && (
        <div className="popup-overlay">
          <div className="popup-form">
            <span className="close-btn" onClick={() => setSelectedProduct(null)}>X</span>
            
              <h2>Parabéns Você Está Participando E Escolheu o Número {escolheu.id}</h2>
              <h3>Não se esqueça de entregar {escolheu.preco} até a data de 13/04/24.</h3>
              <h3>Combine com o papai ou a mamãe a entrega.</h3>
  
              {/*https://wa.me//55XXXXXXXXXXX?text=Tenho%20interesse%20em%20comprar%20seu%20carro*/}
              <center>
                <button className='button-ok' onClick={() => setEscolheu(null)}>Ok</button>
              </center>
          </div>
        </div>
      )}
      
      <div className='footer'>
        <center>
        <p>&copy; 2024 - Criado por Ray B. de Miranda</p>
        </center>
        </div>
      </div>
    );
  
}

export default Home;


  